import { Component, Input } from '@angular/core';
import { FcHeaderComponent } from '../fc-header';
import { FcSidebarComponent } from '../fc-sidebar';
import { FcCenterComponent } from '../fc-center';

@Component({
  selector: 'fc-header-sidebar',
  standalone: true,
  imports: [FcHeaderComponent, FcSidebarComponent, FcCenterComponent],
  templateUrl: './fc-header-sidebar.component.html',
  styleUrl: './fc-header-sidebar.component.scss',
})
export class FcHeaderSidebarComponent {
  @Input({ required: false }) isSidebar: boolean = true;
}
