import { Component, Input } from '@angular/core';

@Component({
  selector: 'fc-link',
  standalone: true,
  imports: [],
  templateUrl: './fc-link.component.html',
  styleUrl: './fc-link.component.scss',
})
export class FcLinkComponent {
  @Input({ required: true }) link!: string;
  public onLink(): void {
    window.open(this.link, '_blank');
  }
}
