<div class="phone-box">
  <div class="phone-box-select">
    <fc-select
      [(value)]="value.code"
      [list]="listCodePhone"
      placeholder="+#"
      type="string"
      width="100px"></fc-select>
  </div>
  <input
    (input)="changeText($event)"
    [style.width]="'100%'"
    [value]="value.number"
    class="input"
    placeholder="Ingrese su número de celular"
    type="text" />
</div>
