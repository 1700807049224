import { Component, Input } from '@angular/core';

@Component({
  selector: 'fc-stepper-title',
  standalone: true,
  imports: [],
  templateUrl: './fc-stepper-title.component.html',
  styleUrl: './fc-stepper-title.component.scss',
})
export class FcStepperTitleComponent {
  @Input({ required: true }) position: number = 0;
  @Input({ required: true }) total: number = 0;
  @Input({ required: true }) title: string = '';
}
