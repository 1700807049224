import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ICountry, IResponseOk } from '../interfaces';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  http: HttpClient = inject(HttpClient);

  public getList(): Observable<IResponseOk<Array<ICountry>>> {
    return this.http.get<IResponseOk<Array<ICountry>>>(
      environment.country.list
    );
  }
}
