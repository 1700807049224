import { Component } from '@angular/core';

@Component({
  selector: 'fc-select-country',
  standalone: true,
  imports: [],
  templateUrl: './fc-select-country.component.html',
  styleUrl: './fc-select-country.component.scss',
})
export class FcSelectCountryComponent {}
