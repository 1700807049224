@if (closeable) {
  <div
    [ngClass]="{
      'type-success': type === 'success',
      'type-info': type === 'info',
      'type-error': type === 'error',
    }"
    class="type">
    @if (message) {
      <div class="message">{{ message }}</div>
      <div class="message__description">{{ description }}</div>
    }

    @if (messages.length > 0) {
      @for (item of messages; track idx; let idx = $index) {
        <div class="message">• {{ item }}</div>
      }
    }
    @if (closeable) {
      <img
        (click)="onClose()"
        alt=""
        class="close"
        src="assets/img/icon_white_close.png" />
    }
  </div>
}
