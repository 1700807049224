import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FcButtonComponent } from '../../atoms';

@Component({
  selector: 'fc-paginator',
  standalone: true,
  imports: [FcButtonComponent],
  templateUrl: './fc-paginator.component.html',
  styleUrl: './fc-paginator.component.scss',
})
export class FcPaginatorComponent implements OnChanges {
  @Input({ required: false }) total: number = 60;
  @Input({ required: false }) pageIndex: number = 1;
  @Input({ required: false }) pageSize: number = 10;

  @Output() pageIndexChange = new EventEmitter<number>();
  listNumbers: number[] = [];

  ngOnChanges(changes: SimpleChanges) {
    if ('total' in changes) {
      this.listNumbers = Array.from(
        { length: Math.ceil(this.total / this.pageSize) },
        (_, i) => i + 1
      );
    }
  }

  onClickPrevious() {}

  onClickNext() {}

  onClickItem(item: number) {}
}
