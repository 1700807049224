import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WhyPartnerSignalService {
  private signalSource = new BehaviorSubject<boolean>(false);

  // Observable que pueden suscribirse otros componentes
  signal$: Observable<boolean> = this.signalSource.asObservable();

  // Método para cambiar el valor de la señal
  sendSignal(signal: boolean) {
    this.signalSource.next(signal);
  }
}
