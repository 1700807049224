import { Component, inject, OnInit } from '@angular/core';
import { IItemSidebar, ISidebar } from '../../../interfaces';
import { Router } from '@angular/router';
import { RoutePathType } from '../../../enums';
import { FcModalJoinComponent } from '../fc-modal-join';

@Component({
  selector: 'fc-sidebar',
  standalone: true,
  imports: [FcModalJoinComponent],
  templateUrl: './fc-sidebar.component.html',
  styleUrl: './fc-sidebar.component.scss',
})
export class FcSidebarComponent implements OnInit {
  public listMenu: Array<ISidebar> = [];
  public showModal = false;

  router: Router = inject(Router);

  public ngOnInit() {
    this.listMenu = [
      {
        title: 'Categorías',
        items: [
          {
            title: 'Ahorro con propósito',
            link: '',
          },
          {
            title: 'Depósito a plazo fijo',
            // link: '',
            link: RoutePathType.URL_DEPOSIT,
          },
          {
            title: 'Préstamos personales',
            link: RoutePathType.URL_PERSONAL_LOANS,
          },
          {
            title: 'Envío de dinero a otros países',
            link: '',
          },
          {
            title: 'Recibir dinero del extranjero',
            link: '',
          },
        ],
        active: true,
      },
    ];
  }

  public onMenu(menu: ISidebar): void {
    menu.active = !menu.active;
  }

  public onItemMenu(item: IItemSidebar): void {
    if (item.link.length > 0) {
      this.router.navigate([item.link]);
    } else {
      this.showModal = !this.showModal;
    }
  }
}
