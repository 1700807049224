@if (show) {
  <div class="modal">
    <div class="modal-container">
      <img
        (click)="onClose()"
        class="modal-container-close"
        src="assets/img/icon_white_close.png"
        alt="" />
      <div class="modal-container-info">
        <div class="modal-container-info__title">
          ¡Estamos en pleno desarrollo de este módulo! Únete a nuestra lista de
          espera y se uno de nuestros primeros socios
        </div>
      </div>
      <div class="modal-container-actions">
        <fc-button type="primary" (fcClick)="onJoin()">Únete al club</fc-button>
      </div>
    </div>
  </div>
}
