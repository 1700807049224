import { Component, Input } from '@angular/core';

@Component({
  selector: 'fc-loading',
  standalone: true,
  imports: [],
  templateUrl: './fc-loading.component.html',
  styleUrl: './fc-loading.component.scss',
})
export class FcLoadingComponent {
  @Input({ required: false }) title: string = 'Cargando';
}
