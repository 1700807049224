@if (show) {
  <div class="modal">
    <div class="modal-container">
      <img
        class="modal-container-close"
        (click)="onClose()"
        src="assets/img/icon_white_close.png"
        alt="" />
      @if (showMessageJoin) {
        <div class="modal-container-info">
          <div class="modal-container-info__title">
            Te invitamos a ser parte del Fintek Club!
          </div>
          <div class="modal-container-info__title">
            Acumula puntos usando nuestros servicios y canjéalos por grandes
            beneficios !
          </div>
        </div>
        <div class="modal-container-actions">
          <fc-button type="primary" (fcClick)="onRegister()"
            >Registrase</fc-button
          >
          <fc-button type="text" (fcClick)="onLogin()">Ingresar</fc-button>
        </div>
      }
      @if (showMessageJoin && showMessageSpecific) {
        <div class="line-divider"></div>
      }
      @if (showMessageSpecific) {
        <div class="modal-container-info-specific">
          <div class="modal-container-info-specific__title">
            Para acceder a este producto, debes presionar el siguiente botón que
            te va redirigir a la página de nuestro aliado
          </div>
        </div>
        <div class="modal-container-actions">
          <fc-button type="secondary" (fcClick)="onRedirect()"
            >Ir al producto</fc-button
          >
        </div>
      }
    </div>
  </div>
}
