import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FcInputComponent } from '../fc-input';
import { NgClass } from '@angular/common';

@Component({
  selector: 'fc-select',
  standalone: true,
  imports: [FcInputComponent, NgClass],
  templateUrl: './fc-select.component.html',
  styleUrl: './fc-select.component.scss',
})
export class FcSelectComponent implements OnChanges {
  @Input({ required: false }) placeholder: string = 'Seleccione valor';
  @Input({ required: false }) width: string = 'auto';
  @Input({ required: false }) list: any = [];
  @Input({ required: true }) type: string = '';
  @Input({ required: false }) property: string = '';
  @Input({ required: false }) search: boolean = false;
  @Input({ required: false }) disabled: boolean = false;
  @ViewChild('select', { static: false }) miDiv?: ElementRef;

  @Input() value: any;
  @Output() valueChange = new EventEmitter<any>();
  @Output() changeClick = new EventEmitter<any>();

  public open: boolean = false;
  public widthSelectItems = '0';
  public listFilters = [];

  ngOnChanges(changes: SimpleChanges) {
    if ('list' in changes) {
      this.listFilters = structuredClone(this.list);
    }
  }

  public onSelect(): void {
    if (this.miDiv) {
      const width = this.miDiv.nativeElement.offsetWidth;
      this.widthSelectItems = `${width}px`;
    }
    this.open = !this.open;
  }

  public onClose(): void {
    this.open = false;
    this.listFilters = structuredClone(this.list);
  }

  public onSelectItem(item: any): void {
    this.open = false;
    this.value = item;
    this.valueChange.emit(item);
    this.changeClick.emit(item);
    this.listFilters = structuredClone(this.list);
  }

  inputSearch($event: string) {
    if ($event === '') {
      this.listFilters = structuredClone(this.list);
    } else {
      if (this.type === 'object') {
        this.listFilters = structuredClone(this.list).filter((item: any) =>
          item[this.property].toLowerCase().startsWith($event.toLowerCase())
        );
      } else {
        this.listFilters = structuredClone(this.list).filter((item: any) =>
          item.toLowerCase().startsWith($event.toLowerCase())
        );
      }
    }
  }
}
