import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  IResponseOk,
  IUbigeoDepartment,
  IUbigeoDistrict,
  IUbigeoProvince,
} from '../interfaces';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UbigeoService {
  http: HttpClient = inject(HttpClient);

  public getListDepartment(): Observable<
    IResponseOk<Array<IUbigeoDepartment>>
  > {
    const params = new HttpParams().set('sort', 'asc');
    return this.http.get<IResponseOk<Array<IUbigeoDepartment>>>(
      environment.ubigeo.listDepartment,
      { params }
    );
  }

  public getListProvince(): Observable<IResponseOk<Array<IUbigeoProvince>>> {
    const params = new HttpParams().set('sort', 'asc');
    return this.http.get<IResponseOk<Array<IUbigeoProvince>>>(
      environment.ubigeo.listProvince,
      { params }
    );
  }

  public getListProvinceDepartment(
    id: string
  ): Observable<IResponseOk<Array<IUbigeoProvince>>> {
    const params = new HttpParams().set('sort', 'asc');
    return this.http.get<IResponseOk<Array<IUbigeoProvince>>>(
      `${environment.ubigeo.listProvinceDepartment}/${id}`,
      { params }
    );
  }

  public getListDistrictProvince(
    id: string
  ): Observable<IResponseOk<Array<IUbigeoDistrict>>> {
    const params = new HttpParams().set('sort', 'asc');
    return this.http.get<IResponseOk<Array<IUbigeoDistrict>>>(
      `${environment.ubigeo.listDistrictProvince}/${id}`,
      { params }
    );
  }
}
