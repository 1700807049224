import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'fc-center',
  standalone: true,
  imports: [NgClass],
  templateUrl: './fc-center.component.html',
  styleUrl: './fc-center.component.scss',
})
export class FcCenterComponent {
  @Input({ required: false }) standardWidth: boolean = true;
  @Input({ required: false }) heightFull: boolean = false;
}
