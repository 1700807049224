import {
  Component,
  DestroyRef,
  DoCheck,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { FcButtonComponent } from '../../atoms';
import { Router } from '@angular/router';
import { RoutePathType } from '../../../enums';
import { PersonalLoanHistoryStorageService } from '../../../services/personal-loan-history-storage.service';
import {
  IFilterSelected,
  IPersonalLoan,
  IRequestCreateHistory,
} from '../../../../features/personal-loans/interfaces';
import { IResponseOk, IUser } from '../../../interfaces';
import { selectUser } from '../../../../stores/auth/auth.selectors';
import { Store } from '@ngrx/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UserPersonalLoanHistoryService } from '../../../services';
import { Subject, switchMap } from 'rxjs';

@Component({
  selector: 'fc-modal-join',
  standalone: true,
  imports: [FcButtonComponent],
  templateUrl: './fc-modal-join.component.html',
  styleUrl: './fc-modal-join.component.scss',
})
export class FcModalJoinComponent implements DoCheck, OnDestroy, OnInit {
  @Input({ required: true }) show!: boolean;
  @Input({ required: false }) showMessageJoin: boolean = true;
  @Input({ required: false }) showMessageSpecific: boolean = false;
  @Input({ required: false }) type: string = '';
  @Input({ required: false }) item: IPersonalLoan = {} as IPersonalLoan;
  @Input({ required: false }) filterSelected: IFilterSelected =
    {} as IFilterSelected;
  @Output() showChange = new EventEmitter<boolean>();

  public createHistory$ = new Subject<any>();
  public userData!: IUser;
  public selectUser$ = this.store.select(selectUser);
  private destroyRef = inject(DestroyRef);

  constructor(
    private store: Store,
    private personalLoanHistoryStorageService: PersonalLoanHistoryStorageService,
    private router: Router,
    private renderer: Renderer2,
    private userPersonalLoanHistoryService: UserPersonalLoanHistoryService
  ) {
    this.createHistory();
  }

  public ngOnInit() {
    this.getSelectUser();
  }

  public ngDoCheck(): void {
    if (this.show) {
      this.addClassHtml();
    }
  }

  public ngOnDestroy() {
    this.removeClassHtml();
  }

  public onClose(): void {
    this.removeClassHtml();
    this.showChange.emit(false);
  }

  public addClassHtml(): void {
    const htmlElement = document.documentElement;
    this.renderer.addClass(htmlElement, 'html-modal');
  }

  public removeClassHtml(): void {
    const htmlElement = document.documentElement;
    this.renderer.removeClass(htmlElement, 'html-modal');
  }

  public onRegister(): void {
    this.router.navigate([RoutePathType.URL_AUTH_REGISTER]);
  }

  public onLogin(): void {
    this.router.navigate([RoutePathType.URL_AUTH_LOGIN]);
  }

  public onRedirect(): void {
    if (this.userData) {
      this.onCreateHistory();
      this.onClose();
    }
    window.open(
      'https://merite.pe?utm_source=fintekclub&utm_medium=fintekclub&utm_campaign=fintekclub',
      '_blank'
    );
  }

  public onCreateHistory(): void {
    const data: IRequestCreateHistory = {
      user: this.userData as IUser,
      personalLoan: this.item,
      filter: this.filterSelected,
      declaredTrafficLight: this.userData.declaredTrafficLight || '',
    };
    this.createHistory$.next(data);
  }

  private getSelectUser(): void {
    this.selectUser$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((user) => {
        if (user) {
          this.userData = user;
        }
      });
  }

  private createHistory(): void {
    this.createHistory$
      .pipe(
        switchMap((data) => {
          return this.userPersonalLoanHistoryService.create(data);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe({
        next: (response: IResponseOk) => {},
        error: () => {
          this.createHistory();
        },
      });
  }
}
