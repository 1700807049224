<div class="paginator">
  <fc-button (fcClick)="onClickPrevious()" type="secondary"> < </fc-button>
  @for (item of listNumbers; track idx; let idx = $index) {
    <fc-button
      (fcClick)="onClickItem(item)"
      [type]="item === pageIndex ? 'primary' : 'secondary'">
      {{ item }}</fc-button
    >
  }
  <fc-button (fcClick)="onClickNext()" type="secondary"> > </fc-button>
</div>
