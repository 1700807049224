import { inject, Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { IResponseOk, IUser } from '../interfaces';
import { environment } from '../../../environments/environment';
import { MessageType } from '../enums';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  http: HttpClient = inject(HttpClient);

  public profile(id: string, token: string): Observable<IResponseOk<IUser>> {
    const headers = new HttpHeaders({
      Authorization: token,
      fcid: id,
    });

    return this.http
      .get<IResponseOk<IUser>>(`${environment.user.profile}`, {
        headers,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          const errorMessage = error.error?.message || MessageType.ERROR_COMMON;
          return throwError(() => new Error(errorMessage));
        })
      );
  }
}
