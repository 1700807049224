import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IResponseOk, IRoadType } from '../interfaces';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RoadTypeService {
  http: HttpClient = inject(HttpClient);

  public getList(): Observable<IResponseOk<Array<IRoadType>>> {
    return this.http.get<IResponseOk<Array<IRoadType>>>(
      environment.roadType.list
    );
  }
}
