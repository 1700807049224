import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IDocumentNumber, IResponseOk } from '../interfaces';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DocumentNumberService {
  http: HttpClient = inject(HttpClient);

  public get(
    type: string,
    documentNumber: string
  ): Observable<IResponseOk<IDocumentNumber>> {
    let params = new HttpParams();
    params = params.set('type', type);
    params = params.set('documentNumber', documentNumber);
    return this.http.get<IResponseOk<IDocumentNumber>>(
      environment.documentNumber.get,
      { params }
    );
  }

  public verify(
    type: string,
    documentNumber: string
  ): Observable<IResponseOk<boolean>> {
    let params = new HttpParams();
    params = params.set('type', type);
    params = params.set('documentNumber', documentNumber);
    return this.http.get<IResponseOk<boolean>>(
      environment.documentNumber.verify,
      { params }
    );
  }
}
