import { inject, Injectable } from '@angular/core';
import { ICurrency, IResponseOk } from '../interfaces';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  http: HttpClient = inject(HttpClient);

  public getList(): Observable<IResponseOk<Array<ICurrency>>> {
    const params = new HttpParams().set('id', 'PEN');
    return this.http.get<IResponseOk<Array<ICurrency>>>(
      environment.currency.list,
      { params }
    );
  }
}
