import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'fc-title',
  standalone: true,
  imports: [NgClass],
  templateUrl: './fc-title.component.html',
  styleUrl: './fc-title.component.scss',
})
export class FcTitleComponent {
  @Input({ required: true }) title!: string;
  @Input({ required: false }) subTitle?: string;
  @Input({ required: false }) color?: string;
}
