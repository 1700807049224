import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass } from '@angular/common';
import { ButtonType } from '../../../enums';

@Component({
  selector: 'fc-button',
  standalone: true,
  imports: [NgClass],
  templateUrl: './fc-button.component.html',
  styleUrl: './fc-button.component.scss',
})
export class FcButtonComponent {
  @Input({ required: true }) type!: string;
  @Input({ required: false }) loading = false;
  @Input({ required: false }) fcColor = '';
  @Input({ required: false }) fcBackground = '';

  @Output() fcClick = new EventEmitter<void>();

  public buttonType = ButtonType;

  public onClick() {
    this.fcClick.emit();
  }
}
