import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private prefix = 'fc-';
  private secretKey = 'fintek-club';
  private isEncrypt: boolean = false;

  constructor() {
    this.isEncrypt = environment.production;
  }

  // Método para guardar un ítem en localStorage
  setItem(key: string, value: any): void {
    const prefixedKey = this.generateKey(key);
    let storedValue: string;

    if (this.isEncrypt) {
      const valueString = JSON.stringify(value);
      storedValue = this.encrypt(valueString);
    } else {
      storedValue = JSON.stringify(value);
    }

    localStorage.setItem(prefixedKey, storedValue);
  }

  // Método para obtener un ítem del localStorage
  getItem(key: string): any {
    const prefixedKey = this.generateKey(key);
    const storedValue = localStorage.getItem(prefixedKey);

    if (storedValue) {
      if (this.isEncrypt) {
        const decryptedValue = this.decrypt(storedValue);
        return JSON.parse(decryptedValue);
      } else {
        return JSON.parse(storedValue);
      }
    }

    return null;
  }

  // Método para eliminar un ítem del localStorage
  removeItem(key: string): void {
    const prefixedKey = this.generateKey(key);
    localStorage.removeItem(prefixedKey);
  }

  // Método para limpiar todo el localStorage con el prefijo
  clear(): void {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key && key.startsWith(this.prefix)) {
        localStorage.removeItem(key);
        i--; // Decrementar el índice para compensar la eliminación de un elemento
      }
    }
  }

  // Método para generar una clave con prefijo
  private generateKey(key: string): string {
    return `${this.prefix}${key}`;
  }

  // Método para encriptar datos
  private encrypt(value: string): string {
    return CryptoJS.AES.encrypt(value, this.secretKey).toString();
  }

  // Método para desencriptar datos
  private decrypt(value: string): string {
    const bytes = CryptoJS.AES.decrypt(value, this.secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  }
}
