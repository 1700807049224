@if (!loading) {
  <button
    [ngClass]="{
      'background-yellow': fcBackground === 'color-yellow',
      'background-blue': fcBackground === 'color-blue',
      'color-white': fcColor === 'color-white',
      'color-yellow': fcColor === 'color-yellow',
      'color-blue': fcColor === 'color-blue',
      'button-primary': type === buttonType.PRIMARY,
      'button-secondary': type === buttonType.SECONDARY,
      'button-text': type === buttonType.TEXT,
    }"
    (click)="onClick()"
    class="button">
    <ng-content></ng-content>
  </button>
} @else {
  <div
    [ngClass]="{
      'background-yellow': fcBackground === 'color-yellow',
      'background-blue': fcBackground === 'color-blue',
      'color-white': fcColor === 'color-white',
      'color-yellow': fcColor === 'color-yellow',
      'button-primary': type === buttonType.PRIMARY,
      'button-secondary': type === buttonType.SECONDARY,
      'button-text': type === buttonType.TEXT,
    }"
    class="button">
    <span
      [ngClass]="{
        'loader-white': fcColor === 'color-white',
      }"
      class="loader"></span>
    Cargando
  </div>
}
