import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'fc-input',
  standalone: true,
  imports: [NgClass],
  templateUrl: './fc-input.component.html',
  styleUrl: './fc-input.component.scss',
})
export class FcInputComponent {
  @Input({ required: false }) placeholder: string = '';
  @Input({ required: false }) type: string = 'text';
  @Input({ required: false }) width: string = 'auto';
  @Input({ required: false }) disabled: boolean = false;
  @Input() value: string = '';
  @Output() valueChange = new EventEmitter<string>();
  @Output() searchInput = new EventEmitter<string>();

  public showPassword = false;

  public changeText($event: Event): void {
    const input = $event.target as HTMLInputElement;
    this.value = input.value;
    this.valueChange.emit(input.value);
    this.searchInput.emit(input.value);
  }

  public onPassword(): void {
    this.showPassword = !this.showPassword;
  }
}
