import {
  Component,
  DoCheck,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  Output,
  Renderer2,
} from '@angular/core';
import { FcButtonComponent } from '../../atoms';

@Component({
  selector: 'fc-modal-information',
  standalone: true,
  imports: [FcButtonComponent],
  templateUrl: './fc-modal-information.component.html',
  styleUrl: './fc-modal-information.component.scss',
})
export class FcModalInformationComponent implements DoCheck, OnDestroy {
  @Input({ required: true }) show!: boolean;
  @Input({ required: true }) title!: string;
  @Input({ required: true }) description!: string;
  @Output() showChange = new EventEmitter<boolean>();

  renderer: Renderer2 = inject(Renderer2);

  get convertDescription() {
    return this.description?.replace(/\n/g, '<br>');
  }

  public ngDoCheck(): void {
    if (this.show) {
      this.addClassHtml();
    }
  }

  public ngOnDestroy() {
    this.removeClassHtml();
  }

  public onClose(): void {
    this.removeClassHtml();
    this.showChange.emit(false);
  }

  public addClassHtml(): void {
    const htmlElement = document.documentElement;
    this.renderer.addClass(htmlElement, 'html-modal');
  }

  public removeClassHtml(): void {
    const htmlElement = document.documentElement;
    this.renderer.removeClass(htmlElement, 'html-modal');
  }
}
