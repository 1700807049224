import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IResponseOk, IReturnDeadline } from '../interfaces';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReturnDeadlineService {
  http: HttpClient = inject(HttpClient);

  public getList(): Observable<IResponseOk<Array<IReturnDeadline>>> {
    const params = new HttpParams().set('sort', 'asc');
    return this.http.get<IResponseOk<Array<IReturnDeadline>>>(
      environment.returnDeadline.list,
      { params }
    );
  }
}
