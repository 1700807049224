export enum RoutePathType {
  URL_BASE = '',
  URL_LANDING_HOME = '',
  URL_PERSONAL_LOANS = '/personal-loans',
  URL_DEPOSIT = '/deposit',
  URL_RECEIVE_MONEY = '/receive-money',
  URL_SAVING = '/saving',
  URL_SEND_MONEY = '/send-money',
  URL_AUTH_LOGIN = '/auth/login',
  URL_AUTH_REGISTER = '/auth/register',
  URL_AUTH_FORGOT_PASSWORD = '/auth/forgot-password',
  URL_AUTH_CONFIRM_REGISTER_PHONE = '/auth/confirm-register-phone',
  URL_AUTH_CONFIRM_REGISTER_EMAIL = '/auth/confirm-register-email',
  URL_AUTH_REGISTER_SUCCESS = '/auth/register-success',
}
