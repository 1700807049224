<fc-header></fc-header>
<fc-center [standardWidth]="false">
  <div class="content">
    @if (isSidebar) {
      <div class="content-sidebar">
        <fc-sidebar></fc-sidebar>
      </div>
    }
    <div class="content-body">
      <ng-content></ng-content>
    </div>
  </div>
</fc-center>
