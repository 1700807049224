import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IResponseOk } from '../interfaces';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IRequestCreateHistory } from '../../features/fixed-term-deposit/interfaces';

@Injectable({
  providedIn: 'root',
})
export class UserFixedTermDepositHistoryService {
  http: HttpClient = inject(HttpClient);

  public create(data: IRequestCreateHistory): Observable<IResponseOk> {
    return this.http.post<IResponseOk>(
      environment.userFixedTermDepositHistory.create,
      data
    );
  }
}
