import {
  Component,
  DoCheck,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  Output,
  Renderer2,
} from '@angular/core';
import { FcButtonComponent } from '../../atoms';
import { Router } from '@angular/router';
import { RoutePathType } from '../../../enums';

@Component({
  selector: 'fc-modal-soon',
  standalone: true,
  imports: [FcButtonComponent],
  templateUrl: './fc-modal-soon.component.html',
  styleUrl: './fc-modal-soon.component.scss',
})
export class FcModalSoonComponent implements DoCheck, OnDestroy {
  @Input({ required: true }) show!: boolean;
  @Output() showChange = new EventEmitter<boolean>();
  renderer: Renderer2 = inject(Renderer2);
  router: Router = inject(Router);

  public ngDoCheck(): void {
    if (this.show) {
      this.addClassHtml();
    }
  }

  public ngOnDestroy() {
    this.removeClassHtml();
  }

  public onClose(): void {
    this.removeClassHtml();
    this.showChange.emit(false);
  }

  public addClassHtml(): void {
    const htmlElement = document.documentElement;
    this.renderer.addClass(htmlElement, 'html-modal');
  }

  public removeClassHtml(): void {
    const htmlElement = document.documentElement;
    this.renderer.removeClass(htmlElement, 'html-modal');
  }

  public onJoin(): void {
    this.router.navigate([RoutePathType.URL_AUTH_REGISTER]);
    this.onClose();
  }
}
