<button (click)="changeCheck()" class="custom-checkbox">
  @if (value) {
    <img
      alt=""
      class="custom-checkbox-img"
      src="assets/svg/checkbox/checkbox-fill.svg" />
  } @else {
    <img
      alt=""
      class="custom-checkbox-img"
      src="assets/svg/checkbox/checkbox-light.svg" />
  }
  <ng-content></ng-content>
</button>
