import { inject, Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { Subject } from 'rxjs';
import { IRequestCreateHistory } from '../../features/personal-loans/interfaces';
import { LocalStorageKeyType } from '../enums';

@Injectable({
  providedIn: 'root',
})
export class PersonalLoanHistoryStorageService {
  localStorageService: LocalStorageService = inject(LocalStorageService);
  private personalLoanHistoryDataSubject: Subject<
    Array<IRequestCreateHistory>
  > = new Subject<Array<IRequestCreateHistory>>();

  constructor() {
    const storedData = this.localStorageService.getItem(
      LocalStorageKeyType.PERSONAL_LOAN_HISTORY
    );
    const personalLoanHistoryData: Array<IRequestCreateHistory> = storedData
      ? storedData
      : [];
    this.personalLoanHistoryDataSubject.next(personalLoanHistoryData);
  }

  setPersonalLoanHistoryData(data: IRequestCreateHistory) {
    const storedData = this.localStorageService.getItem(
      LocalStorageKeyType.PERSONAL_LOAN_HISTORY
    );
    const listData = storedData ? storedData : [];
    listData.push(data);
    this.localStorageService.setItem(
      LocalStorageKeyType.PERSONAL_LOAN_HISTORY,
      listData
    );
    this.personalLoanHistoryDataSubject.next(listData);
  }

  clearPersonalLoanHistoryData() {
    this.localStorageService.removeItem(
      LocalStorageKeyType.PERSONAL_LOAN_HISTORY
    );
    this.personalLoanHistoryDataSubject.next([]);
  }

  getPersonalLoanHistoryData(): Array<IRequestCreateHistory> | null {
    return this.localStorageService.getItem(
      LocalStorageKeyType.PERSONAL_LOAN_HISTORY
    );
  }
}
