import { Component, inject, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { RoutePathType } from '../../../enums';
import { FcButtonComponent, FcInputComponent } from '../../atoms';
import { FcCenterComponent } from '../fc-center';
import { FcSelectCountryComponent } from '../fc-select-country';
import { Subject } from 'rxjs';
import { WhyPartnerSignalService } from '../../../services';
import { Store } from '@ngrx/store';
import { selectIsAuthenticated } from '../../../../stores/auth/auth.selectors';
import { AsyncPipe } from '@angular/common';
import { NzButtonComponent } from 'ng-zorro-antd/button';
import * as AuthActions from '../../../../stores/auth/auth.actions';

@Component({
  selector: 'fc-header',
  standalone: true,
  imports: [
    FcInputComponent,
    FcCenterComponent,
    FcSelectCountryComponent,
    FcButtonComponent,
    AsyncPipe,
    NzButtonComponent,
  ],
  templateUrl: './fc-header.component.html',
  styleUrl: './fc-header.component.scss',
})
export class FcHeaderComponent implements OnDestroy {
  public selectIsAuthenticated$ = this.store.select(selectIsAuthenticated);

  router: Router = inject(Router);
  whyPartnerSignalService: WhyPartnerSignalService = inject(
    WhyPartnerSignalService
  );
  public ngDestroyed$ = new Subject();

  constructor(private store: Store) {}

  public ngOnDestroy(): void {
    this.ngDestroyed$.next({});
  }

  public onLogo(): void {
    this.router.navigate([RoutePathType.URL_LANDING_HOME]);
  }

  onLogin() {
    this.router.navigate([RoutePathType.URL_AUTH_LOGIN]);
  }

  async onLogout() {
    this.store.dispatch(AuthActions.logout());
  }

  public onAction(isAuth?: boolean | null) {
    if (isAuth) {
      this.onLogout();
    } else {
      this.onLogin();
    }
  }

  onRedirect() {
    this.router.navigate([RoutePathType.URL_LANDING_HOME]);
    this.whyPartnerSignalService.sendSignal(true);
  }
}
