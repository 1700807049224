<div class="header-background">
  <fc-center>
    <header class="header">
      <div class="header-info">
        <img
          (click)="onLogo()"
          alt="logo"
          class="header-info__img"
          src="assets/img/logo_fk.png" />
        <nav class="nav"></nav>
      </div>
      <div class="header-actions">
        <div class="text-optional">
          <fc-button (fcClick)="onRedirect()" fcColor="color-blue" type="text"
            >¿Porque unirse al club?
          </fc-button>
        </div>
        @if (selectIsAuthenticated$ | async; as isAuth) {
          <button
            (click)="onAction(isAuth)"
            class="btn-blue"
            nz-button
            nzType="text">
            Cerrar Sesión
          </button>
        } @else {
          <button
            (click)="onAction(false)"
            class="btn-blue"
            nz-button
            nzType="text">
            Zona Socios
          </button>
        }
        <fc-select-country></fc-select-country>
      </div>
    </header>
  </fc-center>
</div>
<div class="header-padding"></div>
