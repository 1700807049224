<div class="radio-group">
  @for (item of list; track idx; let idx = $index) {
    <div class="radio-wrapper" (click)="onSelected(item)">
      @if (type === 'object' && item[property] === value[property]) {
        <img
          class="radio-button-img"
          src="assets/svg/radio-button/radio-button-fill.svg"
          alt="" />
      } @else {
        <img
          class="radio-button-img"
          src="assets/svg/radio-button/radio-button-light.svg"
          alt="" />
      }
      <span>{{ item[property] }}</span>
    </div>
  }
</div>
