import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IAvailabilitySchedule, IResponseOk } from '../interfaces';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AvailabilityScheduleService {
  http: HttpClient = inject(HttpClient);

  public getList(): Observable<IResponseOk<Array<IAvailabilitySchedule>>> {
    return this.http.get<IResponseOk<Array<IAvailabilitySchedule>>>(
      environment.availabilitySchedule.list
    );
  }
}
