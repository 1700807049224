import {
  Component,
  DoCheck,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  Output,
  Renderer2,
} from '@angular/core';
import { FcButtonComponent } from '../../atoms';
import { Router } from '@angular/router';

@Component({
  selector: 'fc-modal-notification',
  standalone: true,
  imports: [FcButtonComponent],
  templateUrl: './fc-modal-notification.component.html',
  styleUrl: './fc-modal-notification.component.scss',
})
export class FcModalNotificationComponent implements DoCheck, OnDestroy {
  @Input({ required: true }) show!: boolean;
  @Input({ required: true }) title!: string;
  @Input({ required: false }) subtitle!: string;
  @Input({ required: true }) type!: string;
  @Output() showChange = new EventEmitter<boolean>();
  @Output() closeClick = new EventEmitter<void>();
  renderer: Renderer2 = inject(Renderer2);
  router: Router = inject(Router);

  public ngDoCheck(): void {
    if (this.show) {
      this.addClassHtml();
    }
  }

  public ngOnDestroy() {
    this.removeClassHtml();
  }

  public onClose(): void {
    this.removeClassHtml();
    this.showChange.emit(false);
    this.closeClick.emit();
  }

  public addClassHtml(): void {
    const htmlElement = document.documentElement;
    this.renderer.addClass(htmlElement, 'html-modal');
  }

  public removeClassHtml(): void {
    const htmlElement = document.documentElement;
    this.renderer.removeClass(htmlElement, 'html-modal');
  }

  public onOk(): void {
    this.showChange.emit(false);
    this.closeClick.emit();
  }
}
