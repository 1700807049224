import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'fc-checkbox',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './fc-checkbox.component.html',
  styleUrl: './fc-checkbox.component.scss',
})
export class FcCheckboxComponent {
  @Input() value: boolean = false;
  @Output() valueChange = new EventEmitter<boolean>();

  changeCheck() {
    this.value = !this.value;
    this.valueChange.emit(this.value);
  }
}
