<div [ngClass]="{ 'center-full': heightFull }" class="center">
  <div
    [ngClass]="{
      'center-standard-item': standardWidth,
      'center-no-standard-item': !standardWidth,
      'center-full': heightFull,
    }">
    <ng-content></ng-content>
  </div>
</div>
