import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import {
  IRequestRegister,
  IResponseLogin,
  IResponseOk,
  IVerifyCodePhone,
} from '../interfaces';
import { environment } from '../../../environments/environment';
import { MessageType } from '../enums';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  http: HttpClient = inject(HttpClient);

  public requestCodePhone(
    code: string,
    number: string
  ): Observable<IResponseOk> {
    return this.http.post<IResponseOk>(environment.auth.requestCodePhone, {
      code,
      number,
    });
  }

  public verifyCodePhone(
    code: string,
    number: string,
    codeSMS: string
  ): Observable<IResponseOk<IVerifyCodePhone>> {
    return this.http.post<IResponseOk<IVerifyCodePhone>>(
      environment.auth.verifyCodePhone,
      {
        code,
        number,
        codeSMS,
      }
    );
  }

  public register(data: IRequestRegister): Observable<IResponseOk> {
    return this.http.post<IResponseOk>(environment.auth.register, data);
  }

  public resendCodeRegister(email: string): Observable<IResponseOk> {
    return this.http.post<IResponseOk>(environment.auth.resendCodeRegister, {
      email,
    });
  }

  public confirmRegister(email: string, code: string): Observable<IResponseOk> {
    return this.http.post<IResponseOk>(environment.auth.confirmRegister, {
      email,
      code,
    });
  }

  public login(
    email: string,
    password: string
  ): Observable<IResponseOk<IResponseLogin>> {
    return this.http
      .post<IResponseOk<IResponseLogin>>(environment.auth.login, {
        email,
        password,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          const errorMessage = error.error?.message || MessageType.ERROR_COMMON;
          return throwError(() => new Error(errorMessage));
        })
      );
  }

  public forgotPassword(email: string): Observable<IResponseOk> {
    return this.http.post<IResponseOk>(environment.auth.forgotPassword, {
      email,
    });
  }

  public confirmForgotPassword(
    email: string,
    code: string,
    password: string
  ): Observable<IResponseOk> {
    return this.http.post<IResponseOk>(environment.auth.confirmForgotPassword, {
      email,
      code,
      password,
    });
  }

  // public async signOut(): Promise<any> {
  //   // return signOut();
  //   return;
  // }
  //
  // public async signOutGlobal(): Promise<any> {
  //   // return signOut({ global: true });
  //   return;
  // }
  //
  // public async getCurrentUser(): Promise<any> {
  //   // return getCurrentUser();
  //   return;
  // }
}
