@if (show) {
  <div class="modal">
    <div class="modal-container">
      <img
        (click)="onClose()"
        alt=""
        class="modal-container-close"
        src="assets/img/icon_black_close.png" />
      <div class="modal-container-info">
        <div class="modal-container-info__title">{{ title }}</div>
        <div class="modal-container-info__description">
          <div [innerHTML]="convertDescription"></div>
        </div>
      </div>
    </div>
  </div>
}
