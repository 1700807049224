import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class JwtService {
  constructor() {}

  // Método para obtener el payload del token
  public getTokenPayload(token: string): any {
    const decodedToken = this.decodeToken(token);
    return decodedToken ? decodedToken : null;
  }

  // Método para verificar si el token es válido
  public isTokenValid(token: string): boolean {
    const decodedToken = this.decodeToken(token);
    if (!decodedToken) {
      return false;
    }
    const currentTime = Math.floor(new Date().getTime() / 1000);
    return decodedToken.exp ? decodedToken.exp > currentTime : false;
  }

  // Método para verificar si el token ha expirado
  public isTokenExpired(token: string): boolean {
    const decodedToken = this.decodeToken(token);
    if (!decodedToken || !decodedToken.exp) {
      return true;
    }
    const currentTime = Math.floor(new Date().getTime() / 1000);
    return decodedToken.exp < currentTime;
  }

  // Método para decodificar un token JWT
  private decodeToken(token: string): any {
    try {
      return jwtDecode(token);
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  }
}
