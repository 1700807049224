import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'fc-radio-group',
  standalone: true,
  imports: [],
  templateUrl: './fc-radio-group.component.html',
  styleUrl: './fc-radio-group.component.scss',
})
export class FcRadioGroupComponent {
  @Input() value: any;
  @Output() valueChange = new EventEmitter<any>();

  @Input({ required: false }) list: any = [];
  @Input({ required: true }) type: string = '';
  @Input({ required: false }) property: string = '';

  onSelected(item: any) {
    this.valueChange.emit(item);
  }
}
