@if (type === 'password') {
  <div class="input-password">
    <form>
      <input
        autocomplete
        (input)="changeText($event)"
        [style.width]="width"
        [value]="value"
        class="input input-password-input"
        placeholder="{{ placeholder }}"
        type="{{ showPassword ? 'text' : 'password' }}" />
    </form>
    <img
      class="input-password-eye"
      (click)="onPassword()"
      src="assets/img/icon_eye_password.png"
      alt="" />
  </div>
} @else {
  <input
    [disabled]="disabled"
    (input)="changeText($event)"
    [style.width]="width"
    [value]="value"
    class="input"
    [ngClass]="{ 'input--disabled': disabled }"
    placeholder="{{ placeholder }}"
    type="{{ type }}" />
}
