import { Component } from '@angular/core';
import { FcHeaderSidebarComponent } from '../fc-header-sidebar';
import { FcCenterComponent } from '../fc-center';
import { FcHeaderComponent } from '../fc-header';

@Component({
  selector: 'app-fc-soon',
  standalone: true,
  imports: [FcHeaderSidebarComponent, FcCenterComponent, FcHeaderComponent],
  templateUrl: './fc-soon.component.html',
  styleUrl: './fc-soon.component.scss',
})
export class FcSoonComponent {}
