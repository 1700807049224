<div class="sidebar">
  @for (menu of listMenu; track menu.title) {
    <div class="sidebar-menu">
      <button class="sidebar-menu-box" (click)="onMenu(menu)">
        <div class="sidebar-menu-box__title">{{ menu.title }}</div>
        @if (menu.active) {
          <img src="assets/svg/icon_arrow_down_white.svg" alt="" />
        } @else {
          <img src="assets/svg/icon_arrow_up_white.svg" alt="" />
        }
      </button>
      @if (menu.active) {
        <div class="sidebar-menu-items">
          @for (item of menu.items; track item.title) {
            <button (click)="onItemMenu(item)" class="sidebar-menu-items-box">
              <div class="">{{ item.title }}</div>
            </button>
          }
        </div>
      }
    </div>
  }
</div>
<div class="sidebar-box"></div>
<fc-modal-join [(show)]="showModal"></fc-modal-join>
