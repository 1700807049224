<div class="container">
  <div
    [ngClass]="{ 'color-light': color === 'light' }"
    class="container__title">
    {{ title }}
  </div>
  @if (subTitle) {
    <div class="container__subTitle">{{ subTitle }}</div>
  }
</div>
