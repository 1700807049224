@if (show) {
  <div class="modal">
    <div class="modal-container">
      @if (type === 'success') {
        <img
          class="modal-container-close"
          (click)="onClose()"
          src="assets/img/icon_white_close.png"
          alt="" />
      }
      <div class="modal-container-info">
        <img
          class="modal-container-info__img"
          src="assets/svg/modal-notification/success-filled.svg"
          alt="" />
        <div class="modal-container-info__title">{{ title }}</div>
        @if (subtitle) {
          <div class="modal-container-info__subtitle">{{ subtitle }}</div>
        }
      </div>
      <div class="modal-container-actions">
        <fc-button type="primary" (fcClick)="onOk()">Entendido</fc-button>
      </div>
    </div>
  </div>
}
