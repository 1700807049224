import { inject, Injectable } from '@angular/core';
import { IEntityType, IResponseOk } from '../interfaces';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EntityTypeService {
  http: HttpClient = inject(HttpClient);

  public getList(): Observable<IResponseOk<Array<IEntityType>>> {
    return this.http.get<IResponseOk<Array<IEntityType>>>(
      environment.entityType.list
    );
  }
}
