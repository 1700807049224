import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FcSelectComponent } from '../../atoms';

@Component({
  selector: 'fc-phone',
  standalone: true,
  imports: [FcSelectComponent],
  templateUrl: './fc-phone.component.html',
  styleUrl: './fc-phone.component.scss',
})
export class FcPhoneComponent {
  @Input() value: any = { code: '', number: '' };
  @Output() valueChange = new EventEmitter<any>();

  public listCodePhone = ['+51'];

  public changeText($event: Event): void {
    const input = $event.target as HTMLInputElement;
    this.valueChange.emit({ code: this.value.code, number: input.value });
  }
}
