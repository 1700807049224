import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'fc-alert',
  standalone: true,
  imports: [NgClass],
  templateUrl: './fc-alert.component.html',
  styleUrl: './fc-alert.component.scss',
})
export class FcAlertComponent {
  @Input({ required: true }) type!: string;
  @Input({ required: false }) message!: string;
  @Input({ required: false }) description!: string;
  @Input({ required: false }) messages: Array<string> = [];
  @Input({ required: false }) closeable: boolean = true;
  @Output() closeClick: EventEmitter<void> = new EventEmitter<void>();

  public onClose(): void {
    this.closeable = false;
    this.closeClick.emit();
  }
}
