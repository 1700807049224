import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IGender, IResponseOk } from '../interfaces';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GenderService {
  http: HttpClient = inject(HttpClient);

  public getList(): Observable<IResponseOk<Array<IGender>>> {
    return this.http.get<IResponseOk<Array<IGender>>>(environment.gender.list);
  }
}
