<div class="config">
  <div
    #select
    (click)="!disabled ? onSelect() : null"
    [ngClass]="{ 'select-disabled': disabled }"
    [style.width]="width"
    class="select">
    <input
      [ngClass]="{ 'select-input-disabled': disabled }"
      autocomplete="off"
      class="select-input"
      placeholder="{{ placeholder }}"
      readonly="readonly"
      type="text"
      value="{{ type === 'object' ? value[property] : value }}" />
    <img alt="" class="select-img" src="assets/img/icon_arrow_down.png" />
  </div>

  @if (open) {
    <div class="select-items" [style.width]="widthSelectItems">
      @if (search) {
        <fc-input
          (searchInput)="inputSearch($event)"
          [width]="'100%'"
          placeholder="Ingrese busqueda"></fc-input>
      }
      @for (item of listFilters; track idx; let idx = $index) {
        <div class="select-items__item" (click)="onSelectItem(item)">
          @if (type === 'object') {
            {{ item[property] }}
          } @else {
            {{ item }}
          }
        </div>
      }
    </div>
    <div (click)="onClose()" class="no-select"></div>
  }
</div>
